export default {
      staking: {
        1: "0x3C01c6c58E61Ae5fFc0e603abd7ebe5d9d27D97a",
        4: "0x3C01c6c58E61Ae5fFc0e603abd7ebe5d9d27D97a",
      },
      ape: {
        137: "0x02B35009aa4547C15856b9cFD4abA8155668Df23",
        80001: "0x78Ac61D76c187A19816Cd8E8858B5AE4573DaF60",
      },
      variable: {
        1: "0xAD93D504631feCA691d0a6EFed72f8344Ee72925",
        4: "0xAD93D504631feCA691d0a6EFed72f8344Ee72925",
      },
    standard: {
        1: "0xe559525118f9Ae71e2b21806B15332D17A00EBfF",
        4: "0xe559525118f9Ae71e2b21806B15332D17A00EBfF",
    },
  };
  